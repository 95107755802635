<template>
  <div>
    <CardItemContainer 
      v-if="rewardList.length > 0" 
      :items="rewardList.map(re => ({ ...re, onClick: updateRewardDetail, styles: setThemeStyle()}))"
      class="rewards"
      title="Your Rewards"
    >
      <template v-slot:left="slotProps">
        <div class="coupon-count">{{slotProps.item.currency}}</div>
      </template>
      <template v-slot:default>
        <i class="iconfont iconright-arrow-2 arrow"></i>
      </template>
    </CardItemContainer>
    <div v-if="rewardList.length == 0" class="rewards-card-list-container text-center">
      <div class="rewards-content-container">
        <div class="rewards-no-found-icon">
          <img :src="require('../../public/images/oops.png')" alt="icon">
        </div>
        <div class="rewards-card-heading-container">
          <div class="rewards-card-title">Oops... No rewards yet!</div>
        </div>
        <div class="rewards-card-content">When you redeem your rewards, you'll see them here.</div>
      </div>
    </div>
  </div>
</template>

<script>
// import BaseLay from "../components/BaseLay";
import { mapActions, mapGetters } from 'vuex';
import { hexToRgb } from '../util';
import CardItemContainer from '../components/CardItemContainer.vue';
export default {
  setup(props) {},
  mounted(){
      this.$api.rewards().then((res) => {
        this.setRewardList(res);
        this.setSpinLoading(false);
      });
  },
  components: {
    // BaseLay,
    CardItemContainer,
  },
  computed: {
    ...mapGetters({
      rewardList: "rewardList",
      themeColor: "themeColor"
    })
  },
  methods:{
    ...mapActions({
      rewardDetail:'rewardDetail',
      setRewardList: "rewardList",
      setSpinLoading: "spinLoading",
    }),
    updateRewardDetail: function(item){
      this.rewardDetail({
        itemTit: item.tit,
        currency: item.currency,
        itemSubtit: item.subtitle,
        icon: item.icon,
        discountCode: item.discountCode,
        styles: this.setThemeStyle(),
      });
      this.$router.push('rewardDetail');
    },
    setThemeStyle() {
      return {
        color: this.themeColor,
        backgroundColor: this.convertBackgroundColor(this.themeColor)
      };
    },
    convertBackgroundColor(color) {
      const rgb = hexToRgb(color);
      return `rgba(${rgb.r} ${rgb.g} ${rgb.b} /0.1)`
    },
  },
};
</script>

<style scoped>
.rewards-page {
  overflow-y: auto;
}
.rewards {
  min-height: 445px;
}
.rewards ::v-deep(.container-list) {
  border: none;
  background-color: #F6F0FFFF;
  margin-bottom: 10px;
  color: #9448FF;
  border-radius: 10px;
  box-sizing: border-box;
}
.rewards ::v-deep(.container-list .coupon-count) {
  margin: 0;
  width: 100px;
  padding-left: 15px;
  color: #9448FF;
  color: inherit;
  font-size: 32px;
  font-weight: 700;
}
.rewards ::v-deep(.container-center .tit) {
  color: #9448FF;
  color: inherit;
  font-size: 14px;
  width: 140px;
}
.rewards ::v-deep(.container-center .sub-tit) {
  color: #8E3DFFFF;
  color: inherit;
  opacity: 0.6;
  font-size: 11px;
  width: 140px;
}
.rewards ::v-deep(.container-right) {
  color: #8E3DFFFF;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 15px;
}
.rewards-card-list-container {
  padding: 15px 26px 26px;
}
.rewards-content-container {
  box-sizing: border-box;
  position: relative;
  height: 445px;
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #fff;
  padding-top: 144px;
  border-radius: 20px;
  font-family: 'Roboto', sans-serif;
}
.rewards-no-found-icon {
  /* 左右居中 */
  margin-left: auto;
  margin-right: auto;
  width: 64px;
  height: 64px;
}
.rewards-no-found-icon img {
  width: 100%;
}
.rewards-card-heading-container {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 24px;
  margin-top: 15px;
}
.rewards-card-content {
  font-size: 14px;
  width: 66%;
  margin: 0 auto;
  color: #0E0300FF;
  
}
.rewards-card-title {
  text-align: center;
  color: #0E0300FF;
  font-size: 18px;
}
</style>
